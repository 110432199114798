
.navigation {
 animation: Global-fadeIn 3s;
 background-color: var(--global-main-bg-color); 
 position: fixed;
 width: 100%;
 display: flex;
 padding-top: 20px;
 padding-bottom: 10px;
 z-index: 999;
}

.navigation-menu ul {
    display: flex;
    height: 100%;
    margin: 0 auto;
    align-items: center;
  }
  .navigation-menu li {
    list-style-type: none;
    margin: 0 1rem;
  }
  .navigation-menu li a {
    text-decoration: none;
    display: block;
    width: 100%;
    height: 100%;
  }

.navAuthorName{
    padding-left: 5%;
    padding-right: 5%;
    justify-content: start;
    flex: 1;
    color: var(--global-accent-text-color);
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: bold;
    transition: color 0.2s ease-in-out;
}
.navAuthorName:hover{
    color: var(--global-main-text-color);
}

.navText {

    color: var(--global-main-text-color);
    text-decoration: none;
}

.navText:hover{
    color: var(--global-accent-text-color);
}

.hamburger {
    border: 0;
    height: 40px;
    width: 40px;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: var(--global-accent-text-color);
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    display: none;
  }
  .hamburger:hover {
    background-color: var(--global-main-text-color);
  }

@media screen and (max-width: 768px){
    .hamburger {
        display: block;
    }
    .navigation-menu ul {
        position: absolute;
        top: 60px;
        left: 0;
        flex-direction: column;
        width: 100%;
        height: calc(100vh - 60px);
        background-color: var(--global-main-bg-color);
        display: none;
    }
    .navigation-menu.expanded ul {
        display: block;
        z-index: 999;
        
    }
    .navigation-menu.expanded ul {
      display: block;
      z-index: 999;
      display: flex;
      align-items: center;
  }

  .navigation-menu.expanded ul li {
    height: 80px;
    line-height: 80px;
}
    .navigation-menu li {
        text-align: center;
        margin: 0;
        font-size: 2rem;
    }

    .nav-toggle-switch {
      display: none;
    }
  }

  .nav-toggle-switch {
    position: relative;
    width: 50px;
    height: 30px;
    
  }
  
  .nav-toggle-label {
    position: absolute;
    width: 100%;
    height: 30px;
    background-color: var(--global-main-accent-bg-color);
    border-radius: 50px;
    cursor: pointer;
  }
  
  .nav-toggle-checkbox {
    position: absolute;
    display: none;
  }
  
  .nav-slider {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    transition: 0.3s;
  }

  .nav-slider::before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    box-shadow: inset 5px -1px 0px 0px var(--global-accent-underline-color);
    background-color: var(--global-main-accent-bg-color);
    transition: 0.3s;
  }
  
  .nav-toggle-checkbox:checked ~ .nav-slider::before {
    transform: translateX(20px);
    background-color: var(--global-accent-underline-color);
    box-shadow: none;
  }
  