:root, :root.light {
    /* Defaults to light if prefers-color-scheme media query does not work */
    --global-main-bg-color: #fefbe9;
    --global-main-text-color: #183a1d;
    --global-main-accent-bg-color: #e1eedd;
    --global-accent-text-color: #f0a04b;
    --global-accent-underline-color: #f6c453;
}

@media (prefers-color-scheme: dark) {
	:root {
		--global-main-bg-color: #0a0a0aff;
        --global-main-text-color: #eee3c1ff;
        --global-main-accent-bg-color: #1a181aff;
        --global-accent-text-color: #d1342fff;
        --global-accent-underline-color: #FF0000;
	}
}

@media (prefers-color-scheme: light) {
    :root {
        --global-main-bg-color: #0f0;
        --global-main-text-color: #183a1d;
        --global-main-accent-bg-color: #e1eedd;
        --global-accent-text-color: #f0a04b;
        --global-accent-underline-color: #f6c453;
    }
}

:root.dark {
    --global-main-bg-color: #0a0a0aff;
    --global-main-text-color: #eee3c1ff;
    --global-main-accent-bg-color: #1a181aff;
    --global-accent-text-color: #d1342fff;
    --global-accent-underline-color: #FF0000;
}
* {
    margin: 0;
    padding: 0;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
    z-index: 1;
}

.Global-Underline {
    text-decoration: underline;
    text-decoration-color: var(--global-accent-underline-color);
}
.Global-FullScreen {
    position: relative;
    /* Height and width fallback for older browsers. */
    height: 100%;
    width: 100%;

    /* Set the height to match that of the viewport. */
    height: 100vh;

    /* Set the width to match that of the viewport. */
    width: 100vw;

    /* Remove any browser-default margins. */
    margin: 0;
}
.Global-HomePage {
    background-color: var(--global-main-bg-color);
}
.Global-BackgroundColour {
    background-color: var(--global-main-bg-color);
}

.Global-Rounded {
    border: 0;
    border-top: 1px solid var(--global-accent-underline-color);
    height: 10px;
}

.Global-Half-Width {
    width: 50%;
}
.Global-altBackgroundColour {
    background-color: var(--global-main-accent-bg-color);
}

.Global-WaveBottomGreen {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    z-index: 0;
    
}

.Global-WaveBottomGreen svg {
    position: relative;
    display: block;
    width: calc(149% + 1.3px);
    height: 264px;
}

.Global-WaveBottomGreen .shape-fill {
    fill: var(--global-main-accent-bg-color);
}

.Global-WaveBottomPale {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    z-index: 0;
}

.Global-WaveBottomPale svg {
    position: relative;
    display: block;
    width: calc(149% + 1.3px);
    height: 264px;
}

.Global-WaveBottomPale .shape-fill {
    fill: var(--global-main-bg-color);
}
@keyframes Global-fadeIn {
    0% { opacity: 0; visibility: hidden;}
    100% { opacity: 1; visibility: visible;}
  }
  

  @media screen and (max-width: 1000px) {

    .Global-WaveBottomGreen svg {
        height: 71px;
    }

    .Global-WaveBottomPale svg {
        height: 71px;
    }
  }